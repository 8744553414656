import React from 'react';
import "../style/Footer.css";


const FooterInfo = () => {
    return (
        <div>
            <div className="absolute absolutely flex justify-center text-white">
                <div className="flex gapy">
                    <a className="readable-text" target="_blank" href="/privacy">
                        Privacy policy
                    </a>{' '}
                    <p className="samesamebutdifferent">|</p>{' '}
                    <a className="readable-text" target="_blank" href="/terms">
                        Terms &amp; conditions &nbsp;{' '}
                    </a>
                </div>
                <div className="samesamebutdifferent"> All the rights reserved to © Imali Media</div>
            </div>
        </div>
    );
}

export default FooterInfo;
