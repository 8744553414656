import "./App.css";
import Hero from "./components/Hero";
import Footer from "./components/FooterInfo";

function App() {

  return (
    <div className="App">
      <div className="relative-wrapper">
        <div className="flex-hero">
          <Hero />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
